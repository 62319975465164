<template>
  <div class="live-casino">
    <HeaderSection
      title="Live Casino"
      :url-section="`${LOBBY_CASINO_URLS.ALL_CASINO}`"
      text-url="Xem Thêm"
      class="container-custom"
      :is-hide-light-arrow="false"
    />
    <div class="frame container-custom">
      <div class="background-image" />
    </div>
    <div class="swiper-container container-custom">
      <Swiper v-bind="swiperOption" class="swiper">
        <SwiperSlide v-for="(item, index) in liveCasinoList" :key="index">
          <nuxt-link :to="item.link" class="slider-item">
            <BaseImg :src="item.image" :alt="item.alt" lazy />
          </nuxt-link>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import { LIVE_CASINOES } from '@/resources/live-casino'
import HeaderSection from '~/components/desktop/pages/home/livecasino/header-section.vue'
import { LOBBY_CASINO_URLS } from '~/config/page-url'

interface CasinoItem {
  link: string
  image: string
  alt: string
}

const liveCasinoList = ref<CasinoItem[]>(LIVE_CASINOES)

const swiperOption = reactive({
  slidesPerView: 5,
  spaceBetween: 15,
  modules: [Navigation]
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/live-casino/index.scss"></style>
