<template>
  <div>
    <Banner />
    <div class="container-custom">
      <GameCenter />
    </div>
    <LiveCasino />
    <JackpotHome />
    <HotGames />
    <div class="container-custom">
      <Promotion />
    </div>
  </div>
</template>
<script setup lang="ts">
import Banner from '~/components/desktop/pages/home/banner/index.vue'
import GameCenter from '~/components/desktop/pages/home/game-center/index.vue'
import JackpotHome from '~/components/desktop/pages/home/jackpot/jackpot-home.vue'
import LiveCasino from '~/components/desktop/pages/home/livecasino/index.vue'
import HotGames from '~/components/desktop/pages/home/hot-games.vue'
import Promotion from '~/components/desktop/pages/home/promotion/index.vue'

onMounted(() => {
  scrollToTop()
})

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}
</script>
