<template>
  <div class="jackpot-home">
    <div class="slot-machine-container">
      <div class="group-jackpot">
        <BaseImg
          v-if="jackpotNumber && listGame.length > 0"
          src="assets/images/components/desktop/pages/home/jackpot/icon_jackpot.png"
          class="jackpot-icon"
        />

        <div v-if="jackpotNumber && listGame.length > 0" class="header__jackpot">
          <AnimateCountUp :number="jackpotNumber" />
        </div>
      </div>
      <div class="game-centers">
        <SlotGameItem v-for="(item, index) in listGame.slice(0, 6)" :key="index" :item="item" :index="index" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import SlotGameItem from '~/components/mobile/pages/home/hot-games/game-item.vue'
import { useGame } from '~/composables/game/useGame'
import { useJackpot } from '~/composables/game/useJackpot'
import { useAppStore } from '~/store/app'
import AnimateCountUp from '~~/components/common/animate-count-up.vue'
import BaseImg from '~/components/common/base-img.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const { onJackpotInterval, onJackpotIntervalClear, onSumJackpotInterval, onSumJackpotIntervalClear } = useJackpot()

const { listGame, fetchListGameJackpot } = useGame()

const jackpotNumber = computed(() => {
  return (
    (sumJackpot?.value.jackpotNohu || 0) +
    (sumJackpot?.value.jackpotIngame || 0) +
    (sumJackpot?.value.jackpotFishing || 0)
  )
})

onMounted(() => {
  onJackpotInterval()
  onSumJackpotInterval()
})
useAsyncData(async () => {
  await fetchListGameJackpot()
})
onBeforeUnmount(() => {
  onJackpotIntervalClear()
  onSumJackpotIntervalClear()
})
</script>

<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/jackpot/jackpot-home.scss"></style>
