import { LOBBY_CASINO_URLS } from '~/config/page-url'

export const LIVE_CASINOES = [
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/rikvip.webp',
    link: LOBBY_CASINO_URLS.CASINO_RIK,
    alt: 'rikvip'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/evolution-gaming.webp',
    link: LOBBY_CASINO_URLS.CASINO_EVO,
    alt: 'evolution'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/go88.webp',
    link: LOBBY_CASINO_URLS.CASINO_GO88,
    alt: 'go88'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/paragmatic.webp',
    link: LOBBY_CASINO_URLS.CASINO_PRAMATIC,
    alt: 'pragmatic'
  },
  {
    image: 'assets/images/components/desktop/pages/home/live-casino/vivo.webp',
    link: LOBBY_CASINO_URLS.CASINO_VIVO,
    alt: 'vivo'
  }
]
