<template>
  <div class="jackpot-home">
    <div class="jackpot-wrapper">
      <div class="eclips" />
      <BaseImg src="assets/images/components/desktop/pages/home/jackpot/human.webp" class="jackpot-peoples" />
      <div class="eclipsRight" />
      <span />
      <div class="slot-machine-container">
        <BaseImg
          v-if="jackpotNumber && listGame.length > 0"
          src="assets/images/components/desktop/pages/home/jackpot/icon_jackpot.png"
          class="jackpot-icon"
        />
        <div v-if="jackpotNumber && listGame.length > 0" class="header__jackpot">
          <AnimateCountUp :number="jackpotNumber" />
        </div>
        <div class="jackpot-swiper-button-next" />
        <Swiper v-bind="swiperOption" class="swiper">
          <SwiperSlide v-for="(item, index) in listGame" :key="index">
            <SlotGameItem :item="item" :show-coin="false" />
          </SwiperSlide>
        </Swiper>
        <div class="jackpot-swiper-button-prev" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import { storeToRefs } from 'pinia'
import SlotGameItem from '~/components/common/game-item.vue'
import { useGame } from '~/composables/game/useGame'
import { useJackpot } from '~/composables/game/useJackpot'
import { useAppStore } from '~/store/app'
import BaseImg from '~/components/common/base-img.vue'
import AnimateCountUp from '~~/components/common/animate-count-up.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const jackpotNumber = computed(() => {
  return (
    (sumJackpot?.value.jackpotNohu || 0) +
    (sumJackpot?.value.jackpotIngame || 0) +
    (sumJackpot?.value.jackpotFishing || 0)
  )
})

const { onJackpotIntervalClear, onSumJackpotIntervalClear } = useJackpot()

const { listGame, fetchListGameJackpot } = useGame()

useAsyncData(async () => {
  await fetchListGameJackpot(8)
})
onBeforeUnmount(() => {
  onJackpotIntervalClear()
  onSumJackpotIntervalClear()
})

const swiperOption = reactive({
  slidesPerView: 4,
  slidesPerGroup: 4,
  spaceBetween: 24,
  centeredSlides: false,
  initialSlide: 4,
  loop: true,
  navigation: {
    nextEl: '.jackpot-swiper-button-next',
    prevEl: '.jackpot-swiper-button-prev'
  },
  modules: [Navigation]
})
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/pages/home/jackpot/jackpot-home.scss"></style>
