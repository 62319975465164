<template>
  <section class="game-center">
    <div class="game-center__body">
      <Item
        v-for="(game, index) in gameCenters.filter((item) => item.url !== LOBBY_GAME_URLS.SLOTS_GAME)"
        :key="index"
        :game="game"
        :index="index"
        @click="router.push(game.url)"
      />
    </div>
  </section>
</template>
<script setup lang="ts">
import Item from '~/components/mobile/pages/home/game-center/item.vue'
import { gameCenters } from '~/resources/gameCenters'
import { LOBBY_GAME_URLS } from '~/config/page-url'
const router = useRouter()
</script>
<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/game-center/index.scss"></style>
