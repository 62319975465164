<template>
  <div class="hot-game">
    <h2 class="hot-game__title">nhiều người chơi</h2>
    <div class="hot-game__content">
      <GameItem v-for="(item, index) in listGame" :key="index" :item="item" />
    </div>
  </div>
</template>

<script setup lang="ts">
import GameItem from '~/components/mobile/pages/home/hot-games/game-item.vue'
import { useGame } from '~/composables/game/useGame'
import { useJackpot } from '~/composables/game/useJackpot'
import { useAppStore } from '~/store/app'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)

const { onJackpotIntervalClear, onSumJackpotIntervalClear } = useJackpot()

const { listGame, fetchTopGames } = useGame()

onBeforeUnmount(() => {
  onJackpotIntervalClear()
  onSumJackpotIntervalClear()
})

useAsyncData(async () => {
  await fetchTopGames(6)
})
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/hot-games/index.scss"></style>
